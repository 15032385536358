import * as React from 'react';
import { useState } from "react";
import { Paper, Button } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAuth } from "../../Hooks/useAuth";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import valid_input_rhinelander from './valid_input_rhinelander';
import { myCustomEncoder, generateXmlBodyStr } from './CustomEncoder';


// MODAL DISPLAY
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const Rhinelander = () => {
  // USERNAME
  const { user, token } = useAuth();
  var currentUser = JSON.parse(user)['email'];
  // var currentUsername = JSON.parse(user)['username'];

  // DATE AND TIME PARSERS
  const dateParser = (dateString) => {
    const formattedDate = dateString.format('YYYY-MM-DD');
    console.log("formattedDate", formattedDate);
    return formattedDate
  };
  const timeParser = (dateString) => {
    const formattedTime = dateString.format('HH:00:00');
    console.log("formattedTime", formattedTime);
    return formattedTime
  };
  const handleDateChange = (event) => {
    setEventDate(event);
    setExpectedEventDate(event.add(1, 'day'));
  };
  const handleTimeChange = (event) => {
    setEventTime(event);
  };

  const [locationId, setLocationId] = useState('RPC');
  const [paramId, setParamId] = useState('HW');
  const [nowDate, setNowDate] = useState(dateParser(dayjs())); //must be in YYYY-MM-DD to FEWS
  const [nowTime, setNowTime] = useState(timeParser(dayjs())); //must be in HH:mm:00 to FEWS
  const [eventDate, setEventDate] = useState(); //must be in YYYY-MM-DD to FEWS
  const [expectedEventDate, setExpectedEventDate] = useState(); //must be in YYYY-MM-DD to FEWS
  const [eventTime, setEventTime] = useState(dayjs());//must be in HH:mm:00 to FEWS

  const eventDateXML = eventDate ? eventDate.format('YYYY-MM-DD') : null;
  const expectedEventDateXML = expectedEventDate ? expectedEventDate.format('YYYY-MM-DD') : null;
  const eventTimeXML = eventTime ? eventTime.format('HH:00:00') : null; //'HH:mm:00'


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [postResponse, setPostResponse] = useState('');

  const [error, setError] = React.useState('');
  const [message, setMessage] = useState('');
  const minDateValidation = dayjs().subtract(14, 'day');



  // VALIDATION LOGIC
  const input_ranges = valid_input_rhinelander[locationId];
  const display_name = input_ranges.DisplayName;
  const min_head = input_ranges.MinHead;
  const max_head = input_ranges.MaxHead;
  const default_head = input_ranges.DefaultHead;
  const min_gen = input_ranges.MinGen;
  const max_gen = input_ranges.MaxGen;
  const default_gen = input_ranges.DefaultGen;
  const min_load1 = input_ranges.MinLoad1;
  const max_load1 = input_ranges.MaxLoad1;
  const default_load1 = input_ranges.DefaultLoad1;
  const min_load2 = input_ranges.MinLoad2;
  const max_load2 = input_ranges.MaxLoad2;
  const default_load2 = input_ranges.DefaultLoad2;
  const min_load3 = input_ranges.MinLoad3;
  const max_load3 = input_ranges.MaxLoad3;
  const default_load3 = input_ranges.DefaultLoad3;
  const min_eastgate = input_ranges.MinEastGate;
  const max_eastgate = input_ranges.MaxEastGate;
  const default_eastgate = input_ranges.DefaultEastGate;
  const min_westgate = input_ranges.MinWestGate;
  const max_westgate = input_ranges.MaxWestGate;
  const default_westgate = input_ranges.DefaultWestGate;
  const min_taintorgate = input_ranges.MinTaintorGate;
  const max_taintorgate = input_ranges.MaxTaintorGate;
  const default_taintorgate = input_ranges.DefaultTaintorGate;
  const min_totalflow = input_ranges.MinTotalFlow;
  const max_totalflow = input_ranges.MaxTotalFlow;
  const default_totalflow = input_ranges.DefaultTotalFlow;

  const head_id = input_ranges.HeadId;
  const gen_id = input_ranges.GenId;
  const load1_id = input_ranges.Load1Id;
  const load2_id = input_ranges.Load2Id;
  const load3_id = input_ranges.Load3Id;
  const expected_load1_id = input_ranges.ExpectedLoad1Id;
  const expected_load2_id = input_ranges.ExpectedLoad2Id;
  const expected_load3_id = input_ranges.ExpectedLoad3Id;

  const eastgate_id = input_ranges.EastGateId;
  const westgate_id = input_ranges.WestGateId;
  const taintorgate_id = input_ranges.TaintorGateId;
  const expected_eastgate_id = input_ranges.ExpectedEastGateId;
  const expected_westgate_id = input_ranges.ExpectedWestGateId;
  const expected_taintorgate_id = input_ranges.ExpectedTaintorGateId;
  
  const flow_id = input_ranges.FlowId;

  const head_unit = input_ranges.HeadUnit;
  const gen_unit = input_ranges.GenUnit;
  const load1_unit = input_ranges.Load1Unit;
  const load2_unit = input_ranges.Load2Unit;
  const load3_unit = input_ranges.Load3Unit;
  const eastgate_unit = input_ranges.EastGateUnit;
  const westgate_unit = input_ranges.WestGateUnit;
  const taintorgate_unit = input_ranges.TaintorGateUnit;
  const flow_unit = input_ranges.FlowUnit;

  const head_parameter_id = input_ranges.HeadParameterId;
  const gen_parameter_id = input_ranges.GenParameterId;
  const load1_parameter_id = input_ranges.Load1ParameterId;
  const load2_parameter_id = input_ranges.Load2ParameterId;
  const load3_parameter_id = input_ranges.Load3ParameterId;
  const eastgate_parameter_id = input_ranges.EastGateParameterId;
  const westgate_parameter_id = input_ranges.WestGateParameterId;
  const taintorgate_parameter_id = input_ranges.TaintorGateParameterId;
  const flow_parameter_id = input_ranges.FlowParameterId;

  const head_qualifier_id = input_ranges.HeadQualifierId;
  const gen_qualifier_id = input_ranges.GenQualifierId;
  const load1_qualifier_id = input_ranges.Load1QualifierId;
  const load2_qualifier_id = input_ranges.Load2QualifierId;
  const load3_qualifier_id = input_ranges.Load3QualifierId;
  const expected_load1_qualifier_id = input_ranges.ExpectedLoad1QualifierId;
  const expected_load2_qualifier_id = input_ranges.ExpectedLoad2QualifierId;
  const expected_load3_qualifier_id = input_ranges.ExpectedLoad3QualifierId;

  const eastgate_qualifier_id = input_ranges.EastGateQualifierId;
  const westgate_qualifier_id = input_ranges.WestGateQualifierId;
  const taintorgate_qualifier_id = input_ranges.TaintorGateQualifierId;
  const expected_eastgate_qualifier_id = input_ranges.ExpectedEastGateQualifierId;
  const expected_westgate_qualifier_id = input_ranges.ExpectedWestGateQualifierId;
  const expected_taintorgate_qualifier_id = input_ranges.ExpectedTaintorGateQualifierId;

  const flow_qualifier_id = input_ranges.FlowQualifierId;

  // SET DEFAULT VALUES
  const [headValue, setHeadValue] = useState(default_head);
  const [genValue, setGenValue] = useState(default_gen);
  const [load1Value, setLoad1Value] = useState(default_load1);
  const [load2Value, setLoad2Value] = useState(default_load2);
  const [load3Value, setLoad3Value] = useState(default_load3);
  const [expectedLoad1Value, setExpectedLoad1Value] = useState(default_load1);
  const [expectedLoad2Value, setExpectedLoad2Value] = useState(default_load2);
  const [expectedLoad3Value, setExpectedLoad3Value] = useState(default_load3);

  const [eastGateValue, setEastGateValue] = useState(default_eastgate);
  const [westGateValue, setWestGateValue] = useState(default_westgate);
  const [taintorGateValue, setTaintorGateValue] = useState(default_taintorgate);
  const [totalFlowValue, setTotalFlowValue] = useState(default_totalflow);

  const [expectedEastGateValue, setExpectedEastGateValue] = useState(default_eastgate);
  const [expectedWestGateValue, setExpectedWestGateValue] = useState(default_westgate);
  const [expectedTaintorGateValue, setExpectedTaintorGateValue] = useState(default_taintorgate);
  const [expectedTotalFlowValue, setExpectedTotalFlowValue] = useState(default_totalflow);

  //TOOLTIPS
  const location_tooltip = `${display_name} (${locationId})`;
  const head_tooltip = `Value must be between ${min_head} and ${max_head} feet (required).`;
  const gen_tooltip = `Value must be between ${min_gen} and ${max_gen} (KW?)`;
  const load1_tooltip = `Value must be between ${min_load1} and ${max_load1} (KW?)`;
  const load2_tooltip = `Value must be between ${min_load2} and ${max_load2} (KW?)`;
  const load3_tooltip = `Value must be between ${min_load3} and ${max_load3} (KW?)`;
  const eastgate_tooltip = `Value must be between ${min_eastgate} and ${max_eastgate} (IN?)`;
  const westgate_tooltip = `Value must be between ${min_westgate} and ${max_westgate} (IN?)`;
  const taintorgate_tooltip = `Value must be between ${min_taintorgate} and ${max_taintorgate} (IN?)`;
  const totalflow_tooltip = `Value must be between ${min_totalflow} and ${max_totalflow} (CFS?)`;

  //Aggregated for XML creation 
  const eventValuesIdsUnits = {
    [head_id]: { paramId: head_parameter_id, eventValue: headValue, eventUnit: head_unit },
    [gen_id]: { paramId: gen_parameter_id, eventValue: genValue, eventUnit: gen_unit, eventQualifierId: gen_qualifier_id },

    [load1_id]: { paramId: load1_parameter_id, eventValue: load1Value, eventUnit: load1_unit, eventQualifierId: load1_qualifier_id },
    [load2_id]: { paramId: load2_parameter_id, eventValue: load2Value, eventUnit: load2_unit, eventQualifierId: load2_qualifier_id },
    [load3_id]: { paramId: load3_parameter_id, eventValue: load3Value, eventUnit: load3_unit, eventQualifierId: load3_qualifier_id },

    [expected_load1_id]: { paramId: load1_parameter_id, eventValue: expectedLoad1Value, eventUnit: load1_unit, eventQualifierId: expected_load1_qualifier_id,  expectedEventDate: expectedEventDateXML },
    [expected_load2_id]: { paramId: load2_parameter_id, eventValue: expectedLoad2Value, eventUnit: load2_unit, eventQualifierId: expected_load2_qualifier_id,  expectedEventDate: expectedEventDateXML },
    [expected_load3_id]: { paramId: load3_parameter_id, eventValue: expectedLoad3Value, eventUnit: load3_unit, eventQualifierId: expected_load3_qualifier_id,  expectedEventDate: expectedEventDateXML },

    [eastgate_id]: { paramId: eastgate_parameter_id, eventValue: eastGateValue, eventUnit: eastgate_unit, eventQualifierId: eastgate_qualifier_id },
    [westgate_id]: { paramId: westgate_parameter_id, eventValue: westGateValue, eventUnit: westgate_unit, eventQualifierId: westgate_qualifier_id },
    [taintorgate_id]: { paramId: taintorgate_parameter_id, eventValue: taintorGateValue, eventUnit: taintorgate_unit, eventQualifierId: taintorgate_qualifier_id },
    
    [expected_eastgate_id]: { paramId: eastgate_parameter_id, eventValue: expectedEastGateValue, eventUnit: eastgate_unit, eventQualifierId: expected_eastgate_qualifier_id,  expectedEventDate: expectedEventDateXML },
    [expected_westgate_id]: { paramId: westgate_parameter_id, eventValue: expectedWestGateValue, eventUnit: westgate_unit, eventQualifierId: expected_westgate_qualifier_id,  expectedEventDate: expectedEventDateXML },
    [expected_taintorgate_id]: { paramId: taintorgate_parameter_id, eventValue: expectedTaintorGateValue, eventUnit: taintorgate_unit, eventQualifierId: taintorgate_qualifier_id,  expectedEventDate: expectedEventDateXML },
    
    //[flow_id]: { paramId: flow_parameter_id, eventValue: totalFlowValue, eventUnit: flow_unit }
  };

  console.log("ValuId Obj: ", eventValuesIdsUnits);
  const xmlBodyStr = generateXmlBodyStr(locationId, eventDateXML, eventTimeXML, nowDate, nowTime, eventValuesIdsUnits);
  //console.log("Helper: ", xmlBodyStrHelper);
  const postData = "piTimeSeriesXmlContent=" + myCustomEncoder(xmlBodyStr);


  function handleSubmit(event) {
    event.preventDefault(); //prevent the default browser behavior of refreshing a page after a form is submitted
    console.log("****xmlBodyStr", xmlBodyStr);
    // ..code to submit form to backend here...
    // Submit(postData);//works
    // const MYPOSTURL = `http://test-202102.westus2.cloudapp.azure.com:8082/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
    // const MYPOSTURL = `wvicdata.northcentralus.cloudapp.azure.com:8082/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
    //const MYPOSTURL = `10.100.1.5:8082/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
    const MYPOSTURL = `https://fews-mc.northcentralus.cloudapp.azure.com:8443/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: postData
    };

    async function fetchData() {
      try {
        const response = await fetch(MYPOSTURL, requestOptions);
        console.log("response", response);
        const data = await response;
        const results = data;
        setPostResponse(results);
        console.log("RESULTS", results.status, results.statusText);
        if (results.status !== 200) {
          setMessage(
            "Sorry, there was an error: " +
            results.status + " " +
            results.statusText
          );
        } else {
          setMessage("Successfully submitted", headValue, eventDate);
        }
      } catch (error) {
        console.log(error);
        setError("ERROR:", error, ". Please try again later.");
      }
    }
    fetchData();
    //handleOpen();
  }


  return (
    <ProjectLayout>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          marginTop: "5%",
          maxWidth: 800,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 align='center'>{display_name} Data Entry</h1>
        <form onSubmit={handleSubmit}>

          <Grid container spacing={2} justifyContent="center" align='center'>
            <Grid item xs={6}>
              {/* CURRENT COLUMN */}
              {/* USERNAME: AUTOPOPULATED */}
              <h3 align="center">Current</h3>
              <Tooltip title="User entering data" placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    id="username"
                    label="Dam Tender"
                    variant="filled"
                    fullWidth
                    value={currentUser}
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {/* DATE */}
              <Tooltip title="Date recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Recorded"
                      disableFuture
                      minDate={minDateValidation}
                      value={eventDate}
                      // placeholderText="Click to select a date"
                      // dateFormat="DD-MM-YYYY"
                      onChange={e => handleDateChange(e)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Tooltip>
              {/* TIME */}
              <Tooltip title="Time recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Time Recorded"
                      value={eventTime}
                      onChange={e => handleTimeChange(e)}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Tooltip>
              {/* HEAD */}
              <Tooltip title={head_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    label="Head (FT)"
                    variant="outlined"
                    fullWidth
                    value={headValue}
                    onInput={e => setHeadValue(e.target.value)}
                    error={(headValue < min_head) || (headValue >= max_head)}
                    type='number'
                    inputProps={{
                      min: min_head,
                      step: 0.01,
                      max: max_head
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* GEN */}
              <Tooltip title={gen_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Gen (KW)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={genValue}
                    onInput={e => setGenValue(e.target.value)}
                    error={(genValue < min_gen) || (genValue >= max_gen)}
                    inputProps={{
                      min: min_gen,
                      step: 0.01,
                      max: max_gen
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* LOAD1 */}
              <Tooltip title={load1_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Load 1 (KW)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={load1Value}
                    onInput={e => setLoad1Value(e.target.value)}
                    error={(load1Value < min_load1) || (load1Value >= max_load1)}
                    inputProps={{
                      min: min_load1,
                      step: 0.01,
                      max: max_load1
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* LOAD2 */}
              <Tooltip title={load2_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Load 2 (KW)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={load2Value}
                    onInput={e => setLoad2Value(e.target.value)}
                    error={(load2Value < min_load2) || (load2Value >= max_load2)}
                    inputProps={{
                      min: min_load2,
                      step: 0.01,
                      max: max_load2
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* LOAD3 */}
              <Tooltip title={load3_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Load 3 (KW)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={load3Value}
                    onInput={e => setLoad3Value(e.target.value)}
                    error={(load3Value < min_load3) || (load3Value >= max_load3)}
                    inputProps={{
                      min: min_load3,
                      step: 0.01,
                      max: max_load3
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* EAST GATE */}
              <Tooltip title={eastgate_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="East Gate (Gate 1) (IN)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={eastGateValue}
                    onInput={e => setEastGateValue(e.target.value)}
                    error={(eastGateValue < min_eastgate) || (eastGateValue >= max_eastgate)}
                    inputProps={{
                      min: min_load3,
                      step: 0.01,
                      max: max_load3
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* WEST GATE */}
              <Tooltip title={westgate_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="West Gate (Gate 2) (IN)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={westGateValue}
                    onInput={e => setWestGateValue(e.target.value)}
                    error={(westGateValue < min_westgate) || (westGateValue >= max_westgate)}
                    inputProps={{
                      min: min_westgate,
                      step: 0.01,
                      max: max_westgate
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* TAINTOR GATE */}
              <Tooltip title={taintorgate_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Taintor Gate (Gate 3) (IN)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={taintorGateValue}
                    onInput={e => setTaintorGateValue(e.target.value)}
                    error={(taintorGateValue < min_taintorgate) || (taintorGateValue >= max_taintorgate)}
                    inputProps={{
                      min: min_taintorgate,
                      step: 0.01,
                      max: max_taintorgate
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* TOTAL FLOW 
              <Tooltip title={totalflow_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Total Flow (CFS)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={totalFlowValue}
                    onInput={e => setTotalFlowValue(e.target.value)}
                    error={(totalFlowValue < min_totalflow) || (totalFlowValue >= max_totalflow)}
                    inputProps={{
                      min: min_totalflow,
                      step: 0.01,
                      max: max_totalflow
                    }}
                  />
                </FormControl>
              </Tooltip>*/}
            </Grid>
            <Grid item xs={6}>
              {/* EXPECTED COLUMN */}
              {/* USERNAME: AUTOPOPULATED */}
              <h3 align="center">Expected</h3>
              <Tooltip title="Null" placement="left">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    id=""
                    label=""
                    variant="filled"
                    fullWidth
                    value=""
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED DATE */}
              <Tooltip title="Date Expected" placement="left">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    label="Expected Date"
                    variant="outlined" 
                    fullWidth
                    value={expectedEventDate ? expectedEventDate.format('MM/DD/YYYY') : ''} 
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED TIME */}
              <Tooltip title="Time Expected" placement="left">
                <FormControl sx={{ m: 1, width: 350 }} required>
                <TextField
                    label="Expected Time"
                    variant="outlined" // This gives it the gray background like you wanted
                    fullWidth
                    value={eventTime.format('HH:00:00')}
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED HEAD */}
              <Tooltip title={head_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    label="Head (FT)"
                    variant="outlined"
                    fullWidth
                    value="---"
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED GEN */}
              <Tooltip title={gen_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Gen (KW)"
                    variant="outlined"
                    fullWidth
                    value="---"
                    disabled
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED LOAD1 */}
              <Tooltip title={load1_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Load 1 (KW)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={load1Value}
                    onInput={e => setExpectedLoad1Value(e.target.value)}
                    error={(load1Value < min_load1) || (load1Value >= max_load1)}
                    inputProps={{
                      min: min_load1,
                      step: 0.01,
                      max: max_load1
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED LOAD2 */}
              <Tooltip title={load2_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Load 2 (KW)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={load2Value}
                    onInput={e => setExpectedLoad2Value(e.target.value)}
                    error={(load2Value < min_load2) || (load2Value >= max_load2)}
                    inputProps={{
                      min: min_load2,
                      step: 0.01,
                      max: max_load2
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED LOAD3 */}
              <Tooltip title={load3_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Load 3 (KW)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={load3Value}
                    onInput={e => setExpectedLoad3Value(e.target.value)}
                    error={(load3Value < min_load3) || (load3Value >= max_load3)}
                    inputProps={{
                      min: min_load3,
                      step: 0.01,
                      max: max_load3
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED EAST GATE */}
              <Tooltip title={eastgate_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="East Gate (Gate 1) (IN)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={eastGateValue}
                    onInput={e => setExpectedEastGateValue(e.target.value)}
                    error={(eastGateValue < min_eastgate) || (eastGateValue >= max_eastgate)}
                    inputProps={{
                      min: min_load3,
                      step: 0.01,
                      max: max_load3
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED WEST GATE */}
              <Tooltip title={westgate_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="West Gate (Gate 2) (IN)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={westGateValue}
                    onInput={e => setExpectedWestGateValue(e.target.value)}
                    error={(westGateValue < min_westgate) || (westGateValue >= max_westgate)}
                    inputProps={{
                      min: min_westgate,
                      step: 0.01,
                      max: max_westgate
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED TAINTOR GATE */}
              <Tooltip title={taintorgate_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Taintor Gate (Gate 3) (IN)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={taintorGateValue}
                    onInput={e => setExpectedTaintorGateValue(e.target.value)}
                    error={(taintorGateValue < min_taintorgate) || (taintorGateValue >= max_taintorgate)}
                    inputProps={{
                      min: min_taintorgate,
                      step: 0.01,
                      max: max_taintorgate
                    }}
                  />
                </FormControl>
              </Tooltip>
              {/* EXPECTED TOTAL FLOW 
              <Tooltip title={totalflow_tooltip} placement="left">
                <FormControl sx={{ m: 1, width: 350 }}>
                  <TextField
                    label="Total Flow (CFS)"
                    variant="outlined"
                    type='number'
                    fullWidth
                    value={totalFlowValue}
                    onInput={e => setExpectedTotalFlowValue(e.target.value)}
                    error={(totalFlowValue < min_totalflow) || (totalFlowValue >= max_totalflow)}
                    inputProps={{
                      min: min_totalflow,
                      step: 0.01,
                      max: max_totalflow
                    }}
                  />
                </FormControl>
              </Tooltip>*/}

            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: -3 }}>
            <Grid item>
              {/* SUBMIT */}
              <Button sx={{ m: 1, mt: 4, width: 350 }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                margin="normal"
                onClick={handleSubmit}
                disabled={!eventDate}
              >
                Submit
              </Button>
            </Grid>
          </Grid>


        </form>
      </Paper>
    </ProjectLayout>
  );
};
