export default {
    "KIN": {       
        "DisplayName": "Kings",
        
        "MinHead": 1458,
        "MaxHead": 1459,
        "DefaultHead": 1458.5,
        "HeadId": "HW",
        "HeadUnit": "FT",
        
        "MinTurbineFlow": 0,
        "MaxTurbineFlow": 1000,
        "DefaultTurbineFlow": 0,
        "TurbineId": "Turbines",
        "TurbineParameterId": "VS",
        "TurbineUnit": "KW",
        "TurbineQualifierId": "Turbine_Total",

        "MinGate1": 0,
        "MaxGate1": 100,
        "DefaultGate1": 0,
        "Gate1Id": "Gate_1",
        "Gate1Unit": "IN",

        "MinGate2": 0,
        "MaxGate2": 100,
        "DefaultGate2": 0,
        "Gate2Id": "Gate_2",
        "Gate2Unit": "IN",

        "MinGate3": 0,
        "MaxGate3": 100,
        "DefaultGate3": 0,
        "Gate3Id": "Gate_3",
        "Gate3Unit": "IN",
        
        "MinTotalFlow": 0,
        "MaxTotalFlow": 100000,
        "DefaultTotalFlow": 0,
        "FlowId": "QU",  //<!--Discharge, controlled by regulating outlet-->
        "FlowUnit": "CFS"
    }
}
