import { useState, useEffect, useRef } from "react";
import { Paper, Typography, IconButton } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import PlaceIcon from '@mui/icons-material/Place';
import { NaturalLakes } from "../Forms/NaturalLakes";
import { isNaturalLakeGroup } from "../Helpers/groupCheck";
import { useNavigate } from "react-router-dom"; // Import useNavigate

export const MyNaturalLakes = () => {
  const [naturalLake, setNaturalLake] = useState("");
  const [user, setUser] = useState(null);
  const prevNaturalLakeRef = useRef("");
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const userObj = JSON.parse(window.localStorage.getItem("afs_user"));
    console.log("userObj", userObj);

    if (userObj) {
      setUser(userObj);
    }
  }, []);

  useEffect(() => {
    prevNaturalLakeRef.current = naturalLake;
  }, [naturalLake]);

  const handleBackToSummaryPg = () => {
    setNaturalLake("");
  };
  console.log("MY NAT LAKE:", naturalLake);

  return (
    <ProjectLayout>
      {!naturalLake && (
        <Paper
          sx={{
            p: 2,
            margin: "auto",
            marginTop: "5%",
            maxWidth: 400,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">My Locations</Typography>
          {/* <Typography variant="p">user: {user}</Typography> */}
          {user?.groups.filter((group) => isNaturalLakeGroup(group.name) )
              .map((group) => {
            const groupName =
              group.name.charAt(0).toUpperCase() + group.name.slice(1);
            //Natural Lakes
            let groupNameDisplay = groupName.replace("LVD", "Lac Vieux Desert");
            groupNameDisplay = groupNameDisplay.replace("TWN", "Twin Lakes ");
            groupNameDisplay = groupNameDisplay.replace("BUK", "Buckatahpon");
            groupNameDisplay = groupNameDisplay.replace("DEE", "Long-On-Deerskin");
            groupNameDisplay = groupNameDisplay.replace("LDS", "Little Deerskin");
            groupNameDisplay = groupNameDisplay.replace("SMI", "Seven Mile");
            groupNameDisplay = groupNameDisplay.replace("NMI", "Lower Nine Mile");
            groupNameDisplay = groupNameDisplay.replace("BUR", "Burnt Rollways Chain");
            groupNameDisplay = groupNameDisplay.replace("SCP", "Sugar Camp Chain");
            groupNameDisplay = groupNameDisplay.replace("LSG", "Little St. Germain");
            groupNameDisplay = groupNameDisplay.replace("BSG", "Big St. Germain");
            groupNameDisplay = groupNameDisplay.replace("SPN", "South Pelican");
            groupNameDisplay = groupNameDisplay.replace("NPN", "North Pelican");
            groupNameDisplay = groupNameDisplay.replace("MIN", "Minocqua Chain");
            groupNameDisplay = groupNameDisplay.replace("SQL", "Squirrel");
            groupNameDisplay = groupNameDisplay.replace("PCL", "Pickerel");
            groupNameDisplay = groupNameDisplay.replace("GIL", "Gilmore");


 
            
            return (
              <IconButton
                key={group.id}
                onClick={() => {
                  prevNaturalLakeRef.current = groupName;
                  //setNaturalLake(groupName);
                  navigate(`/natural-lakes/${group.name}`);
                }}
              >
                <PlaceIcon /> {groupNameDisplay}
              </IconButton>
            );
          })}
        </Paper>
      )}

      
      {naturalLake && (
        // <p>{naturalLake}</p>
        <NaturalLakes naturalLake={naturalLake}/>
        // <Dashboard
        //   naturalLake={naturalLake}
        //   handleBackToSummaryPg={handleBackToSummaryPg}
        // />
      )}
    </ProjectLayout>
 );
};
