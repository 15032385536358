export default {
    "ROT":
        {       
            "DisplayName": "Rothschild",
            "Temperature": true,
            "MinTemp": -55,
            "MaxTemp": 114,
            "DefaultTemp": 50,
            "TempId": "Temp",
            "TempParameterId": "T.obs",
            "TempUnit": "F"
        },
        "GDM":
        {       
            "DisplayName": "Grandmother",
            "Temperature": true,
            "MinTemp": -55,
            "MaxTemp": 114,
            "DefaultTemp": 50,
            "TempId": "Temp",
            "TempParameterId": "T.obs",
            "TempUnit": "F"
        }
    }