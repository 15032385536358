export default {
    "LVD": {
        "Class": "Natural",
        "DisplayName": "Lac Vieux Desert",
        "MinElev": 1679.36,
        "MaxElev": 1681.53,
        "BaseElev": 1600,
        "DefaultVal": 80.51,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 34.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": false,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Gate 2",
        "Gate2Default": 99.99,
        "Gate2QualifierId": "Gate_2"
    },
    "TWN": {
        "Class": "Natural",
        "DisplayName": "Twin Lakes",
        "MinElev": 1680.57,
        "MaxElev": 1682.57,
        "BaseElev": 1600,
        "DefaultVal": 81.55,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 57.0,
        "Gate1QualifierId": "Gate_1"
    },
    "BUK": {
        "Class": "Natural",
        "DisplayName": "Buckatahpon",
        "MinElev": 1638.35,
        "MaxElev": 1641.52,
        "BaseElev": 1600,
        "DefaultVal": 40.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 38.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": false,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Gate 2",
        "Gate2Default": 99.99,
        "Gate2QualifierId": "Gate_2"
    },
    "DEE": {
        "Class": "Natural",
        "DisplayName": "Long-On-Deerskin",
        "MinElev": 1695.84,
        "MaxElev": 1698.43,
        "BaseElev": 1600,
        "DefaultVal": 97.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 36.0,
        "Gate1QualifierId": "Gate_1"
    },
    "LDS": {
        "Class": "Natural",
        "DisplayName": "Little Deerskin",
        "MinElev": 1640.49,
        "MaxElev": 1642.16,
        "BaseElev": 1600,
        "DefaultVal": 41.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 28.5,
        "Gate1QualifierId": "Gate_1"
    },
    "SMI": {
        "Class": "Natural",
        "DisplayName": "Seven Mile",
        "MinElev": 1645.81,
        "MaxElev": 1650.14,
        "BaseElev": 1600,
        "DefaultVal": 48.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 38.0,
        "Gate1QualifierId": "Gate_1"
    },
    "NMI": {
        "Class": "Natural",
        "DisplayName": "Lower Nine Mile",
        "MinElev": 1639.18,
        "MaxElev": 1643.76,
        "BaseElev": 1600,
        "DefaultVal": 41.55,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 53.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Right Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 43.0,       
        "Gate2QualifierId": "Gate_2"
    },
    "BUR": {
        "Class": "Natural",
        "DisplayName": "Burnt Rollways Chain",
        "MinElev": 1622.96,
        "MaxElev": 1625.71,
        "BaseElev": 1600,
        "DefaultVal": 24.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 42.5,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Right Deep Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 85.5,
        "Gate2QualifierId": "Gate_2"
    },
    "SCP": {
        "Class": "Natural",
        "DisplayName": "Sugar Camp Chain",
        "MinElev": 1592.32,
        "MaxElev": 1597.82,
        "BaseElev": 1500,
        "DefaultVal": 95.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 82.0,
        "Gate1QualifierId": "Gate_1"
    },
    "LSG": {
        "Class": "Natural",
        "DisplayName": "Little St. Germain",
        "MinElev": 1612.05,
        "MaxElev": 1613.88,
        "BaseElev": 1600,
        "DefaultVal": 13.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 27.0,
        "Gate1QualifierId": "Gate_1"
    },
    "BSG": {
        "Class": "Natural",
        "DisplayName": "Big St. Germain",
        "MinElev": 1588.16,
        "MaxElev": 1591.16,
        "BaseElev": 1500,
        "DefaultVal": 89.55,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 49.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Center Lift Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 56.0,
        "Gate2QualifierId": "Gate_2",
        "Gate3": true,
        "Gate3Id": "Gate3",
        "Gate3ParameterId": "NO",
        "Gate3Unit": "IN",
        "Gate3DisplayName": "Right Lift Gate (Gate 3)",
        "Gate3Default": 12.0,
        "Gate3Min": 0.0,
        "Gate3Max": 54.0,
        "Gate3QualifierId": "Gate_3"
    },
    "PCL": {
        "Class": "Natural",
        "DisplayName": "Pickerel",
        "MinElev": 1581.34,
        "MaxElev": 1591.34,
        "BaseElev": 1500,
        "DefaultVal": 86.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Head Dam Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 128.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Canal Dam Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 39.0,
        "Gate2QualifierId": "Gate_2"
    },
    "SPN": {
        "Class": "Natural",
        "DisplayName": "South Pelican",
        "MinElev": 1589.98,
        "MaxElev": 1591.98,
        "BaseElev": 1500,
        "DefaultVal": 91.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 24.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Right Lift Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 24.0,
        "Gate2QualifierId": "Gate_2"
    },
    "NPN": {
        "Class": "Natural",
        "DisplayName": "North Pelican",
        "MinElev": 1566.6,
        "MaxElev": 1569.6,
        "BaseElev": 1500,
        "DefaultVal": 68.05,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 46.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Center Lift Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.0,
        "Gate2Max": 46.0,
        "Gate2QualifierId": "Gate_2",
        "Gate3": true,
        "Gate3Id": "Gate3",
        "Gate3ParameterId": "NO",
        "Gate3Unit": "IN",
        "Gate3DisplayName": "Right Lift Gate (Gate 3)",
        "Gate3Default": 12.0,
        "Gate3Min": 0.0,
        "Gate3Max": 46.0,
        "Gate3QualifierId": "Gate_3"
    },
   
    "MIN": {
        "Class": "Natural",
        "DisplayName": "Minocqua Chain",
        "MinElev": 1639.18,
        "MaxElev": 1643.76,
        "BaseElev": 1600,
        "DefaultVal": 41.55,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Left Roll Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 49.0,
        "Gate1QualifierId": "Gate_1",
        "Gate2": true,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Right Roll Gate (Gate 2)",
        "Gate2Default": 12.0,
        "Gate2Min": 0.00,
        "Gate2Max": 49.0,
        "Gate2QualifierId": "Gate_2"
    },
    "SQL": {
        "Class": "Natural",
        "DisplayName": "Squirrel",
        "MinElev": 1562.51,
        "MaxElev": 1564.93,
        "BaseElev": 1500,
        "DefaultVal": 63.55,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": true,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": true,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Lift Gate (Gate 1)",
        "Gate1Default": 12.0,
        "Gate1Min": 0.0,
        "Gate1Max": 33.5,
        "Gate1QualifierId": "Gate_1"
    },
    
    "GIL": {
        "Class": "Natural",
        "DisplayName": "Gilmore",
        "MinElev": 1582,
        "MaxElev": 1583,
        "BaseElev": 1500,
        "DefaultVal": 82.5,
        "Head": true,
        "HeadId": "Head",
        "HeadParameterId": "HW",
        "HeadUnit": "FT",
        "Tail": false,
        "TailId": "Tail",
        "TailParameterId": "TW",
        "TailUnit": "FT",
        "Gate1": false,
        "Gate1Id": "Gate1",
        "Gate1ParameterId": "NO",
        "Gate1Unit": "IN",
        "Gate1DisplayName": "Gate 1",
        "Gate1Default": 99.99,
        "Gate1Min": 0.00,
        "Gate1Max": 99.99,
        "Gate1QualifierId": "Gate_1",
        "Gate2": false,
        "Gate2Id": "Gate2",
        "Gate2ParameterId": "NO",
        "Gate2Unit": "IN",
        "Gate2DisplayName": "Gate 2",
        "Gate2Default": 99.99,
        "Gate2QualifierId": "Gate_2"
    }
}
