import * as React from 'react';
import { useState } from "react";
import { Paper, Button } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '../../Utils/MyAdapterDayJs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAuth } from "../../Hooks/useAuth";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import valid_input_kings from './valid_input_kings' ;
import { myCustomEncoder , generateXmlBodyStr } from './CustomEncoder';


// MODAL DISPLAY
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const Kings = () => {
  // USERNAME
  const { user, token } = useAuth();
  var currentUser = JSON.parse(user)['email'];
  // var currentUsername = JSON.parse(user)['username'];
  
  // DATE AND TIME PARSERS
  const dateParser = (dateString) => {
    const formattedDate = dateString.format('YYYY-MM-DD');
    console.log("formattedDate",formattedDate);
    return formattedDate
  };
  const timeParser = (dateString) => {
    const formattedTime = dateString.format('HH:00:00');
    console.log("formattedTime",formattedTime);
    return formattedTime
  };
  const handleDateChange = (event) => {
    setEventDate(event);
  };
  const handleTimeChange = (event) => {
    setEventTime(event);
  };

  const [locationId, setLocationId] = useState('KIN');
  const [paramId, setParamId] = useState('HW');
  const [nowDate, setNowDate] = useState(dateParser(dayjs())); //must be in YYYY-MM-DD to FEWS
  const [nowTime, setNowTime] = useState(timeParser(dayjs())); //must be in HH:mm:00 to FEWS
  const [eventDate, setEventDate] = useState(); //must be in YYYY-MM-DD to FEWS
  const [eventTime, setEventTime] = useState(dayjs());//must be in HH:mm:00 to FEWS
  const eventDateXML = eventDate ? eventDate.format('YYYY-MM-DD') : null;
  const eventTimeXML = eventTime ? eventTime.format('HH:00:00') : null; //'HH:mm:00'


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [postResponse, setPostResponse] = useState('');

  const [error, setError] = React.useState('');
  const [message, setMessage] = useState('');
  const minDateValidation = dayjs().subtract(14, 'day');


  // VALIDATION LOGIC
  const input_ranges = valid_input_kings[locationId];
  const display_name = input_ranges.DisplayName;
  const min_head = input_ranges.MinHead;
  const max_head = input_ranges.MaxHead;
  const default_head = input_ranges.DefaultHead;
  const min_turbineflow = input_ranges.MinTurbineFlow;
  const max_turbineflow = input_ranges.MaxTurbineFlow;
  const default_turbineflow = input_ranges.DefaultTurbineFlow;
  const min_gate1 = input_ranges.MinGate1;
  const max_gate1 = input_ranges.MaxGate1;
  const default_gate1 = input_ranges.DefaultGate1;
  const min_gate2 = input_ranges.MinGate2;
  const max_gate2 = input_ranges.MaxGate2;
  const default_gate2 = input_ranges.DefaultGate2;
  const min_gate3 = input_ranges.MinGate3;
  const max_gate3 = input_ranges.MaxGate3;
  const default_gate3 = input_ranges.DefaultGate3;
  const min_totalflow = input_ranges.MinTotalFlow;
  const max_totalflow = input_ranges.MaxTotalFlow;
  const default_totalflow = input_ranges.DefaultTotalFlow;

  const head_id = input_ranges.HeadId;
  const turbine_id = input_ranges.TurbineId;
  const gate1_id = input_ranges.Gate1Id;
  const gate2_id = input_ranges.Gate2Id;
  const gate3_id = input_ranges.Gate3Id;
  const flow_id = input_ranges.FlowId;

  const head_unit = input_ranges.HeadUnit;
  const turbine_unit = input_ranges.TurbineUnit;
  const gate1_unit = input_ranges.Gate1Unit;
  const gate2_unit = input_ranges.Gate2Unit;
  const gate3_unit = input_ranges.Gate3Unit;
  const flow_unit = input_ranges.FlowUnit;

  const head_qualifier_id = input_ranges.HeadQualifierId;
  const turbine_qualifier_id = input_ranges.TurbineQualifierId;
  const gate1_qualifier_id = input_ranges.Gate1QualifierId;
  const gate2_qualifier_id = input_ranges.Gate2QualifierId;
  const gate3_qualifier_id = input_ranges.Gate3QualifierId;
  const flow_qualifier_id = input_ranges.FlowQualifierId;
  

  // SET DEFAULT VALUES
  const [headValue, setHeadValue] = useState(default_head);
  const [turbineFlowValue, setTurbineFlowValue] = useState(default_turbineflow);
  const [gate1Value, setGate1Value] = useState(default_gate1);
  const [gate2Value, setGate2Value] = useState(default_gate2);
  const [gate3Value, setGate3Value] = useState(default_gate3);
  const [totalFlowValue, setTotalFlowValue] = useState(default_totalflow);

  //TOOLTIPS
  const location_tooltip = `${display_name} (${locationId})`;
  const head_tooltip = `Value must be between ${min_head} and ${max_head} (FT) (required).`;
  const turbine_tooltip = `Value must be between ${min_turbineflow} and ${max_turbineflow} (CFS?)`;
  const gate1_tooltip = `Value must be between ${min_gate1} and ${max_gate1} (KW?)`;
  const gate2_tooltip = `Value must be between ${min_gate2} and ${max_gate2} (KW?)`;
  const gate3_tooltip = `Value must be between ${min_gate3} and ${max_gate3} (KW?)`;
  const totalflow_tooltip = `Value must be between ${min_totalflow} and ${max_totalflow} (CFS?)`;
  
  //Aggregated for XML creation 

  const eventValuesIdsUnits = {
    [head_id]: { eventValue: headValue, eventUnit: head_unit },
    [turbine_id]: { eventValue: turbineFlowValue, eventUnit: turbine_unit, eventQualifierId: turbine_qualifier_id},
    [gate1_id]: { eventValue: gate1Value, eventUnit: gate1_unit, eventQualifierId: gate1_qualifier_id},
    [gate2_id]: { eventValue: gate2Value, eventUnit: gate2_unit, eventQualifierId: gate2_qualifier_id },
    [gate3_id]: { eventValue: gate3Value, eventUnit: gate3_unit, eventQualifierId: gate3_qualifier_id},
    [flow_id]: { eventValue: totalFlowValue, eventUnit: flow_unit, eventQualifierId: flow_qualifier_id},
    };

  console.log("ValuId Obj: ", eventValuesIdsUnits);
  const xmlBodyStr = generateXmlBodyStr(locationId, eventDateXML, eventTimeXML, nowDate, nowTime, eventValuesIdsUnits);
  //console.log("Helper: ", xmlBodyStrHelper);
  const postData = "piTimeSeriesXmlContent=" + myCustomEncoder(xmlBodyStr);
  
  
    function handleSubmit(event) {
      event.preventDefault(); //prevent the default browser behavior of refreshing a page after a form is submitted
      console.log("****xmlBodyStr", xmlBodyStr);
      // ..code to submit form to backend here...
      // Submit(postData);//works
      // const MYPOSTURL = `http://test-202102.westus2.cloudapp.azure.com:8082/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
      // const MYPOSTURL = `wvicdata.northcentralus.cloudapp.azure.com:8082/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
      //const MYPOSTURL = `10.100.1.5:8082/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
      const MYPOSTURL = `https://fews-mc.northcentralus.cloudapp.azure.com:8443/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
  
      const requestOptions = {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          body: postData
      };
   
      async function fetchData() {
        try {
          const response = await fetch( MYPOSTURL, requestOptions );
          console.log("response", response);
          const data = await response;
          const results = data;
          setPostResponse(results);
          console.log("RESULTS", results.status, results.statusText);
          if (results.status !== 200) {
            setMessage(
              "Sorry, there was an error: " +
              results.status + " " +
              results.statusText
            );
          } else {
            setMessage("Successfully submitted", headValue, eventDate);
          } 
        } catch (error) {
            console.log(error);
            setError("ERROR:",error,". Please try again later.");
        }
      }
      fetchData();
      //handleOpen();
    }


  return(
    <ProjectLayout>
          <Paper
            sx={{
                p: 2,
                margin: "auto",
                marginTop: "5%",
                maxWidth: 400,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
            >
    <h1 align='center'>{display_name} Data Entry</h1>
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center" align='center'>
        <Grid item>
          {/* USERNAME: AUTOPOPULATED */}
          <Tooltip title="User entering data" placement="right">
              <FormControl sx={{ m: 1, width: 350}} required>
                <TextField
                  id="username"
                    label="Dam Tender"
                    variant="filled"
                    fullWidth
                    value={currentUser}
                    disabled
                  />
              </FormControl>
            </Tooltip>
            {/* DATE */}
            <Tooltip title="Date recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Recorded"
                      disableFuture
                      minDate={minDateValidation}
                      value={eventDate}
                      dateFormat="MM-DD-YYYY" 
                      format="MM-DD-YYYY"
                      onChange={e=>handleDateChange(e)} 
                    /> 
                  </LocalizationProvider>
                </FormControl>
            </Tooltip>
            {/* TIME */}
            <Tooltip title="Time recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker  
                    label="Time Recorded"
                    value={eventTime}
                    onChange={e=>handleTimeChange(e)} 
                  />
                </LocalizationProvider>
              </FormControl>
            </Tooltip>
            {/* HEAD */}
            <Tooltip title={head_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    label="Head (feet)"
                    variant="outlined"
                    fullWidth
                    value={headValue}
                    onInput={ e=>setHeadValue(e.target.value)}
                    error={(headValue < min_head) || (headValue >= max_head)}
                    type='number'
                    inputProps={{
                      min: min_head, 
                      step: 0.01,
                      max: max_head
                    }}
                  />
              </FormControl>
            </Tooltip>
            {/* GEN */}
            <Tooltip title={turbine_tooltip} placement="right">
              <FormControl sx={{ m: 1, width: 350}}>
                  <TextField
                      label="Gen (KW)"
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={turbineFlowValue}
                      onInput={ e=>setTurbineFlowValue(e.target.value)}
                      error={(turbineFlowValue < min_turbineflow) || (turbineFlowValue >= max_turbineflow)}
                      inputProps={{
                        min: min_turbineflow, 
                        step: 0.01,
                        max: max_turbineflow
                      }}
                  />
              </FormControl>
            </Tooltip>
            {/* LOAD1 */}
            <Tooltip title={gate1_tooltip} placement="right">
              <FormControl sx={{ m: 1, width: 350}}>
                  <TextField
                      label="Gate 1 (IN)"
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={gate1Value}
                      onInput={ e=>setGate1Value(e.target.value)}
                      error={(gate1Value < min_gate1) || (gate1Value >= max_gate1)}
                      inputProps={{
                        min: min_gate1, 
                        step: 0.01,
                        max: max_gate1
                      }}
                  />
              </FormControl>
            </Tooltip>
             {/* LOAD2 */}
             <Tooltip title={gate2_tooltip} placement="right">
              <FormControl sx={{ m: 1, width: 350}}>
                  <TextField
                      label="Gate 2 (IN)"
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={gate2Value}
                      onInput={ e=>setGate2Value(e.target.value)}
                      error={(gate2Value < min_gate2) || (gate2Value >= max_gate2)}
                      inputProps={{
                        min: min_gate2, 
                        step: 0.01,
                        max: max_gate2
                      }}
                  />
              </FormControl>
            </Tooltip>
             {/* LOAD3 */}
             <Tooltip title={gate3_tooltip} placement="right">
              <FormControl sx={{ m: 1, width: 350}}>
                  <TextField
                      label="Gate 3 (IN)"
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={gate3Value}
                      onInput={ e=>setGate3Value(e.target.value)}
                      error={(gate3Value < min_gate3) || (gate3Value >= max_gate3)}
                      inputProps={{
                        min: min_gate3, 
                        step: 0.01,
                        max: max_gate3
                      }}
                  />
              </FormControl>
            </Tooltip>

             {/* TOTAL FLOW */}
             <Tooltip title={totalflow_tooltip} placement="right">
              <FormControl sx={{ m: 1, width: 350}}>
                  <TextField
                      label="Total Flow (CFS)"
                      variant="outlined"
                      type='number'
                      fullWidth
                      value={totalFlowValue}
                      onInput={ e=>setTotalFlowValue(e.target.value)}
                      error={(totalFlowValue < min_totalflow) || (totalFlowValue >= max_totalflow)}
                      inputProps={{
                        min: min_totalflow, 
                        step: 0.01,
                        max: max_totalflow
                      }}
                  />
              </FormControl>
            </Tooltip>

             {/* SUBMIT */}
          <Button  sx={{m: 1,  mt: 4, width: 350}}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              margin="normal"
              onClick={handleSubmit}
              disabled={!eventDate}
              >
                  Submit
            </Button>
        
          </Grid>
        </Grid>
      </form>
    </Paper>
  </ProjectLayout>
  );
};
