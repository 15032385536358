import * as React from 'react';
import { useState } from "react";
import { Paper, Button } from "@mui/material";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '../../Utils/MyAdapterDayJs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useAuth } from "../../Hooks/useAuth";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Modal from '@mui/material/Modal';
import valid_input_rothschild from './valid_input_temperature' ;
import { myCustomEncoder , generateXmlBodyStr } from './CustomEncoder';
import valid_input_temperature from './valid_input_temperature';
import { useParams } from "react-router-dom";


// MODAL DISPLAY
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const Temperature = () => {
  // USERNAME
  const { user, token } = useAuth();
  const { tempLocation } = useParams();

  var currentUser = JSON.parse(user)['email'];
  // var currentUsername = JSON.parse(user)['username'];
  
  // DATE AND TIME PARSERS
  // DATE AND TIME PARSERS
  const dateParser = (dateString) => {
    const formattedDate = dateString.format('YYYY-MM-DD');
    console.log("formattedDate",formattedDate);
    return formattedDate
  };
  const dateNowParser = (nowDateString) => {
    const nowFormattedDate = nowDateString.format('YYYY-MM-DD');
    console.log("nowFormattedDate",nowFormattedDate);
    return nowFormattedDate
  };
  const timeParser = (dateString) => {
    const formattedTime = dateString.format('HH:00:00');
    console.log("formattedTime", formattedTime);
    return formattedTime
  };
  const timeNowParser = (dateString) => {
    const nowFormattedTime = dateString.format('HH:00:00');
    console.log("nowFormattedTime", nowFormattedTime);
    return nowFormattedTime
  };
  const handleDateChange = (event) => {
    setEventDate(event);
  };
  const handleTimeChange = (event) => {
    setEventTime(event);
  };

  
  const [locationId, setLocationId] = useState(tempLocation);
  const [paramId, setParamId] = useState('T');
  const [nowDate, setNowDate] = useState(dateNowParser(dayjs())); //must be in YYYY-MM-DD to FEWS
  const [nowTime, setNowTime] = useState(timeNowParser(dayjs())); //must be in HH:mm:00 to FEWS
  const [eventDate, setEventDate] = useState(); //must be in YYYY-MM-DD to FEWS
  const [eventTime, setEventTime] = useState(dayjs());//must be in HH:mm:00 to FEWS
  const eventDateXML = eventDate ? eventDate.format('YYYY-MM-DD') : null;
  const eventTimeXML = eventTime ? eventTime.format('HH:00:00') : null; //'HH:mm:00'
 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [postResponse, setPostResponse] = useState('');
  const [error, setError] = React.useState('');
  const [message, setMessage] = useState('');
  const minDateValidation = dayjs().subtract(14, 'day');


  // VALIDATION LOGIC
  console.log(locationId);
  const input_ranges = valid_input_temperature[locationId];
  const display_name = input_ranges.DisplayName;
  const min_temp = input_ranges.MinTemp;
  const max_temp = input_ranges.MaxTemp;
  const temp_id = input_ranges.TempId;
  const temp_unit = input_ranges.TempUnit;
  const temp_parameter_id = input_ranges.TempParameterId;

  const default_temp = input_ranges.DefaultTemp;


  // SET DEFAULT VALUES
  const [tempValue, setTempValue] = useState(default_temp);
  //Temperature in C in FEWS
  function fahrenheitToCelsius(fahrenheit) {
    const celsius = (fahrenheit - 32) * 5/9;
  }
  const tempCValue = fahrenheitToCelsius(tempValue);


  //TOOLTIPS
  const location_tooltip = `${display_name} (${locationId})`;
  const temp_tooltip = `Value must be between ${min_temp} and ${max_temp} °F (required).`;

//Aggregated for XML creation 
const eventValuesIdsUnits = {
  [temp_id]: { paramId: temp_parameter_id, eventValue: tempCValue, eventUnit: temp_unit },
  };

  
console.log("ValuId Obj: ", eventValuesIdsUnits);
const xmlBodyStr = generateXmlBodyStr(locationId, eventDateXML, eventTimeXML, nowDate, nowTime, eventValuesIdsUnits);
//console.log("Helper: ", xmlBodyStrHelper);
const postData = "piTimeSeriesXmlContent=" + myCustomEncoder(xmlBodyStr);

//POST
function handleSubmit(event) {
    event.preventDefault(); //prevent the default browser behavior of refreshing a page after a form is submitted
    console.log("XML Post Helper: \n ", xmlBodyStr);
  
    // ..code to submit form to backend here...
    // Submit(postData);//works
    const MYPOSTURL = `https://fews-mc.northcentralus.cloudapp.azure.com:8443/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
    // const MYPOSTURL = `wvicdata.northcentralus.cloudapp.azure.com:8082/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
    // const MYPOSTURL = `http://10.100.1.5:8082/gateway/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;
    // const MYPOSTURL = `10.100.1.5:8082/FewsWebServices/rest/fewspiservice/v1/timeseries?filterId=WebApp&convertDatum=false`;

    console.log("MYPOSTURL IS:", MYPOSTURL);
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: postData
    };
 
    async function fetchData() {
      try {
        const response = await fetch( MYPOSTURL, requestOptions );
        console.log("response", response);
        const data = await response;
        const results = data;
        setPostResponse(results);
        console.log("RESULTS", results.status, results.statusText);
        if (results.status !== 200) {
          setMessage(
            "Sorry, there was an error: " +
            results.status + " " +
            results.statusText
          );
        } else {
          setMessage("Successfully submitted", tempValue, eventDate);
        } 
      } catch (error) {
          console.log(error);
          setError("ERROR:",error,". Please try again later.");
      }
    }
    fetchData();
    // handleOpen();
  }


  return(
          <Paper
            sx={{
                p: 2,
                margin: "auto",
                marginTop: "5%",
                maxWidth: 400,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
            >
    <h1 align='center'>{display_name} Temperature Entry</h1>
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center" align='center'>
        <Grid item>
          {/* USERNAME: AUTOPOPULATED */}
          <Tooltip title="User entering data" placement="right">
              <FormControl sx={{ m: 1, width: 350}} required>
                <TextField
                  id="username"
                    label="Dam Tender"
                    variant="filled"
                    fullWidth
                    value={currentUser}
                    disabled
                  />
              </FormControl>
            </Tooltip>
            {/* DATE */}
            <Tooltip title="Date recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Recorded"
                      disableFuture
                      minDate={minDateValidation}
                      value={eventDate}
                      // placeholderText="Click to select a date"
                      // dateFormat="DD-MM-YYYY"
                      onChange={e=>handleDateChange(e)} 
                    /> 
                  </LocalizationProvider>
                </FormControl>
            </Tooltip>
            {/* TIME */}
            <Tooltip title="Time recorded (required)" placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker  
                    label="Time Recorded"
                    value={eventTime}
                    onChange={e=>handleTimeChange(e)} 
                    format='HH:mm:00'
                  />
                </LocalizationProvider>
              </FormControl>
            </Tooltip>
            {/* TEMPERATURE */}
            <Tooltip title={temp_tooltip} placement="right">
                <FormControl sx={{ m: 1, width: 350 }} required>
                  <TextField
                    label="Temperature (°F)"
                    variant="outlined"
                    fullWidth
                    value={tempValue}
                    onInput={ e=>setTempValue(e.target.value)}
                    error={(tempValue < min_temp) || (tempValue >= max_temp)}
                    type='number'
                    inputProps={{
                      min: min_temp, 
                      step: 0.01,
                      max: max_temp
                    }}
                  />
              </FormControl>
            </Tooltip>

            {/* SUBMIT */}
              <Button  sx={{m: 1,  mt: 4, width: 350}}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  margin="normal"
                  onClick={handleSubmit}
                  disabled={!eventDate}
                  >
                    Submit
              </Button>
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      This button isn't active yet, stay tuned please!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {message}
                    </Typography>
                  </Box>
                </Modal>
              </div>
        
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
