import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
// import { Dashboard } from "./Components/Dashboard/Dashboard";
import { AuthProvider } from "./Providers/AuthProvider";
import { PrivateRoute } from "./Components/Routes/PrivateRoute";
import { Public } from "./Components/Public/Public";

import { LandingPage } from "./Components/Layouts/LandingPage";

import { MyNaturalLakes } from "./Components/Dashboard/MyNaturalLakes";
import { NaturalLakes } from "./Components/Forms/NaturalLakes";

import { MyBigFive } from "./Components/Dashboard/MyBigFive";
import { BigFive } from "./Components/Forms/BigFive";

import { MyTemperature } from "./Components/Dashboard/MyTemperature";
import { Temperature } from "./Components/Forms/Temperature";


// import { NaturalLakes } from "./Components/Forms/NaturalLakes";
import { Rhinelander } from "./Components/Forms/Rhinelander";
import { Kings } from "./Components/Forms/Kings";
//import { Temperature } from "./Components/Forms/Temperature";

// import { BigFive } from "./Components/Forms/BigFive";
// import cors from 'cors';
// app.get('/cors', (req, res) => {
//   res.set('Access-Control-Allow-Origin', '*');
//   res.send({ "msg": "This has CORS enabled " })
//   })


export default function App() {
  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Public />} />
        <Route path="/landing" 
            element={<PrivateRoute>
              <LandingPage />
            </PrivateRoute>} />
        <Route path="/natural-lakes" 
              element={<PrivateRoute>
                <MyNaturalLakes />
              </PrivateRoute>} />
        <Route path="/natural-lakes/:naturalLake" 
              element={<PrivateRoute>
                <NaturalLakes />
                </PrivateRoute>} />


        {/* <Route path="/natural-lakes"
              element={<MyNaturalLakes/>} 
              /> */}
          <Route path="/big-five" 
              element={<PrivateRoute>
                <MyBigFive />
              </PrivateRoute>} />
          <Route path="/big-five/:bigFiveLake" 
              element={<PrivateRoute>
                <BigFive />
              </PrivateRoute>} />


        <Route path="/rhinelander" 
              element={<PrivateRoute>
                <Rhinelander />
              </PrivateRoute>} />      
        <Route path="/kings" 
              element={<PrivateRoute>
                <Kings />
              </PrivateRoute>} />   

        <Route path="/temperature" 
              element={<PrivateRoute>
                <MyTemperature />
              </PrivateRoute>} />     

        <Route path="/temperature/:tempLocation" 
              element={<PrivateRoute>
                <Temperature />
              </PrivateRoute>} />       
      </Routes>
    </Router>
    </AuthProvider>
  );
}
