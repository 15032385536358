import { useState } from "react";
import { Typography, Box, Button, Modal, AppBar, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
};
export const Disclaimer = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const disclaimerText = `Consultant makes no warranty that the RTI WVIC Portal ("Portal") is accurate or that use of the Portal will be uninterrupted or error-free. Consultant makes no warranty of any kind regarding, and user assumes the entire risk of, use of the Portal and of reliance on its results and performance. Consultant will not be liable to user for any injury, loss, claim or damage caused in whole or in part by user's misuse of the Portal. Consultant makes no warranty, express or implied, or warranty arising by operation of law or otherwise, including without limitation warranties of merchantability or fitness for a particular purpose.`;
  return (
    <>
      <Button size="small" sx={{ m: 2, color: "white" }} onClick={handleOpen}>
        <InfoOutlinedIcon />
        Disclaimer
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Disclaimer-info-modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AppBar sx={{ background: "#002D5B" }}>
            <Toolbar
              sx={{ background: "#002D5B", justifyContent: "space-between" }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Disclaimer
              </Typography>
              <Button
                size="large"
                sx={{ m: 1, color: "white" }}
                onClick={handleClose}
              >
                <CloseIcon />
              </Button>
            </Toolbar>
          </AppBar>
          <Typography
            id="modal-modal-text"
            variant="p"
            sx={{ m: 3, marginTop: "15%" }}
          >
            {disclaimerText}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
