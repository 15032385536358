import { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";

export const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [user, setUser] = useState("");
  const [secret, setSecret] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    setError("");
    try {
      const loginResult = await login(user, secret);

      if (loginResult.status === 200) {
        navigate("/landing");
      } else if (loginResult.status === 401) {
        setError(
          `Unauthorized Access (${loginResult.status}): Try your credentials again, or contact an administrator`
        );
      } else {
        setError(
          `An error occurred with the status code: ${loginResult.status}. Please try again later.`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
      <TextField
        sx={{ margin: "2%" }}
        label="User Name"
        variant="outlined"
        value={user}
        onChange={(e) => setUser(e.target.value)}
      />
      <TextField
        sx={{ margin: "2%" }}
        label="Password"
        type="password"
        variant="outlined"
        value={secret}
        onChange={(e) => setSecret(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleLogin();
          }
        }}
      />

      <Button
        sx={{
          margin: "2%",
          background: "#bfbfbf",
          color: "black",
          "&:hover": {
            backgroundColor: "#d5e3f2",
          },
        }}
        variant="contained"
        onClick={handleLogin}
      >
        Log in
      </Button>
    </>
  );
};
