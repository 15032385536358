export function generateXmlBodyStr(locationId, eventDateXML, eventTimeXML, nowDate, nowTime, eventValuesIdsUnits) {
  let xmlBodyStr = `<?xml version="1.0" encoding="UTF-8"?>
  <TimeSeries xmlns="http://www.wldelft.nl/fews/PI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.wldelft.nl/fews/PI http://fews.wldelft.nl/schemas/version1.0/pi-schemas/pi_timeseries.xsd" version="1.12">`;

  xmlBodyStr += `<timeZone>-5.0</timeZone>`;

  for (const [Id, { paramId, eventValue, eventUnit, eventQualifierId,expectedEventDate }] of Object.entries(eventValuesIdsUnits)) {
    if (Id !== "undefined") { 
      xmlBodyStr += `
      <series> 
        <header>
          <type>instantaneous</type>
          <moduleInstanceId>DamTender_WebApp</moduleInstanceId>
          <locationId>${locationId}</locationId>
          <parameterId>${paramId}</parameterId>
          <qualifierId>DamTender</qualifierId>`; 
      
      // Add optional eventQualifierId if present
      if (eventQualifierId) {
        xmlBodyStr += `
          <qualifierId>${eventQualifierId}</qualifierId>`;
      }
      
      xmlBodyStr += `
          <timeStep unit="nonequidistant"/>
          <startDate date="${eventDateXML}" time="${eventTimeXML}"/>
          <endDate date="${nowDate}" time="${nowTime}"/>
          <missVal>-999.0</missVal>
          <stationName>${locationId}</stationName>
          <units>${eventUnit}</units>
          <creationDate>${nowDate}</creationDate>
          <creationTime>${nowTime}</creationTime>
        </header>
        <event date="${expectedEventDate ? expectedEventDate : eventDateXML}" time="${eventTimeXML}" value="${eventValue}" flag="0"/>
      </series>`;
    }
  }

  xmlBodyStr += `
  </TimeSeries>`;

  return xmlBodyStr;
}

  

export function myCustomEncoder(unsafe) {
    unsafe = unsafe.replace(/\s+/g, '%20');
    return unsafe.replace(/[<>&'"]/g , function (c) {
        switch (c) {
            case '<': return '%3C';
            case '>': return '%3E';
            case '?': return '%3F';
            case '=': return '%3D';
            case '"': return '%22';
            case '/g': return '%20';
            case '/t': return '%20';        
        }
    });
  }/*
  export function myCustomEncoder(xmlString) {
    return encodeURIComponent(xmlString)
        .replace(/%20/g, '+')  // Replace spaces with '+'
        .replace(/%3C/g, '%3C')  // '<'
        .replace(/%3E/g, '%3E')  // '>'
        .replace(/%22/g, '%22')  // '"'
        .replace(/%2F/g, '%2F')  // '/'
        .replace(/%3D/g, '%3D')  // '='
        .replace(/%3A/g, '%3A'); // ':'
}*/