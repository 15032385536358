import React, { useState, createContext } from "react";
import { REACT_APP_API_ROUTE } from "../Utils/constants";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const localStorageToken = window.localStorage.getItem("afs_token");
  const localStorageUser = window.localStorage.getItem("afs_user");

  const [token, setToken] = useState(localStorageToken);
  const [user, setUser] = useState(localStorageUser);

  const login = async (clientId, clientSecret) => {
    const result = await asyncLogin(clientId, clientSecret);

    if (result.status === 200) {
      const tokenPayload = await result.json();
      window.localStorage.setItem("afs_token", JSON.stringify(tokenPayload));
      setToken(tokenPayload);

      // TO DO: better flow and error handling!!
      const userResult = await getUser(tokenPayload);
      if (userResult.status !== 200) {
        return { status: 401 };
      }

      return result;
    } else {
      window.localStorage.removeItem("afs_token");
      window.localStorage.removeItem("afs_user");
      setToken("");
      setUser("");
      return result;
    }
  };

  const logout = async () => {
    window.localStorage.removeItem("afs_token");
    window.localStorage.removeItem("afs_user");
    setToken("");
    setUser("");
    console.log("The User has logged out");
  };

  const asyncLogin = async (clientId, clientSecret) => {
    const url = REACT_APP_API_ROUTE + "/token/";
    console.log('url', url);
    console.log(process.env);
    try {
      const result = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          username: clientId,
          password: clientSecret,
        }),
      });
      console.log('result',result);
      return result;
    } catch (error) {
      console.log("there was a login error: ", error);
      return error;
    }
  };

  const getUser = async (token) => {
    if (!token) {
      token = JSON.parse(window.localStorage.getItem("afs_token"));
    }
    // what if there is no token?
    const url = REACT_APP_API_ROUTE + "/users/me/";
    if (token) {
      try {
        const result = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token.access}`,
          },
        });
        const user = await result.json();
        window.localStorage.setItem("afs_user", JSON.stringify(user));

        // Check if the stored user data differs from the fetched user data
        //if (JSON.stringify(localStorageUser) !== JSON.stringify(user)) {
         // console.log("Updated User Data");
          //window.localStorage.setItem("afs_user", JSON.stringify(user));
        //}
        setUser(user);
        return result;
      } catch (err) {
        console.error(err);
        return err;
      }
    }
  };

  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
